// Imports
// ------
import React from 'react';
import Seo from '@parts/Seo';
import PageTransition from '@parts/PageTransition';
import Legal from '@parts/Legal/Cookies';

// Component
// ------
function TermsPage({ transitionStatus, entry, exit }) {
	// NOTE • Props
	const transProps = {
		status: transitionStatus,
		entry: entry,
		exit: exit,
	};

	return (
		<>
			<Seo />

			<PageTransition {...transProps}>
				<Legal />
			</PageTransition>
		</>
	);
}

export default TermsPage;
